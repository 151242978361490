import React from "react";
import { Link } from "gatsby";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Travel Baseball Team | Northern Virginia" description="Welcome to the home of the Bobcats of NoVA Showcase Baseball. Our Vision is to provide a positive, competitive baseball experience to all athletes." />
    <div className="Hero">
      
        <div className="HeroGroup">
        <p><br></br></p><p><br></br></p><p><br></br></p><p><br></br></p>
        
      </div>
    </div>
    <div className="mainContent"> 
    <div className="sponsorwrapper"><h2 className="center ignore">Our Vision</h2>
          <hr></hr>
          <p className="center">
            To provide a positive, competitive baseball experience to the
            athletes and their families while focusing on the fundamentals of
            baseball to enhance the player’s abilities for the future.
          </p></div><p><br></br></p>  
      <h2 className="center">Our Mission</h2>
      <div className="valuegrid">
        <div>
          <h3>Player Development</h3>
          <p>
            All Bobcat players have access to off season programs with some of
            the best trainers that Virginia has to offer. The programs and camps
            have been designed to help develop your child's individual skills to
            prepare them for the next level.
          </p>
        </div>
        <div>
          <h3>Increased Player Visibility</h3>
          <p>
            Skill is only part of the battle, getting seen is the other. Summer
            and Fall Showcases at some of the best colleges in VA, MD, NC and PA
            puts our players in the best position to be seen by college and pro
            scouts.
          </p>
        </div>
        <div>
          <h3>Mentorship</h3>
          <p>
            One of the greatest values of mentoring is the ability to see ahead
            what our players cannot see in themselves and to help them navigate
            a course to their destination. We do more than coach our players on
            the field. We push past the field by coaching our players on how to
            speak to college coaches and scouts, how to write emails to schools
            of interest and how to negotiate scholarships.
          </p>
        </div>
        <div>
          <h3>Helping Kids Get To College</h3>
          <p>
            The Bobcat organizations #1 goal is getting our kids to college. We
            believe in helping our players utilize their athletic abilities to
            create opportunities for themselves at the collegiate level. While
            baseball is a part of that strategy our biggest hope is that each
            player will use their college career to create opportunities for
            themselves that transcend the baseball field.
          </p>
        </div>
      </div>
      <p>&nbsp;</p>
      <p className="center">
        <a
          className="btn_center"
          href="https://melvinbrownii.typeform.com/to/kjTt87"
        >
          Become a Bobcat
        </a>
      </p>
      <p>&nbsp;</p>
  
    
    </div>
    <div className="mainContent">
      <hr></hr> <p>&nbsp;</p>
      <p>&nbsp;</p>
      <TwitterTimelineEmbed
  sourceType="profile"
  screenName="bobcatsnova"
  noHeader
  noFooter
  options={{height: 400}}
/>  <p>&nbsp;</p>
<p>&nbsp;</p>
      <p>&nbsp;</p><hr></hr> <p>&nbsp;</p>
    </div>
    <div className="testimonialwrapper">
      <h2>Testimonials</h2> 
      <div className="testimonialgrid">
        <div className="testimonial">
        <img width="125px" src={require("../images/Group 2.png")} />
          
          <h3>Varley Febo</h3>
          <p>
            The Bobcats experience is something we will never forget and are
            proud to have been a part of. I really want to thank you for all
            that you have done for Anthony. The one-on-one instruction allowed
            Anthony to get to another level that he could have never achieved on
            his own. Anthony is playing his 2nd season at Christopher Newport
            University and that is a testament to the backing of the Bobcats!
          </p>
        </div>
        <div className="testimonial">
        <img width="125px" src={require("../images/Group.png")} />
          <h3>Jessica Bertrand</h3>
          <p>
            Bobcats President and Coach, Melvin Brown, runs an A+ organization.
            If your son wants to play College Baseball, the Bobcats are the
            organization you want to be a part of. My son never made his High
            School Team and is currently playing College Baseball because he was
            given an opportunity to shine playing for the Bobcats! Coach Melvin
            will work with you son, one-on-one, to ensure that he is the best he
            can be on and off of the field. The Bobcats are family!
          </p>
        </div>
        <div className="testimonial">
          <img
            width="125px"
            src={require("../images/Peter _ Jessica Kettner.png")}
          />
          <h3>Peter Kettner</h3>
          <p>
            Being a part of the Bobcat family is a privilege for us as parents
            and our son Julian. They’ve given him the tools and the opportunity
            to become a prepared college baseball prospect. We’ve watched our
            son grow into a confident person and player. I’ve seen his
            motivation to maintain and improve his GPA, SAT, and self motivate
            to be better athlete. All of this while having fun playing ball.
            Like I said, its been a privilege to be a part of the Bobcats of
            NOVA family.{" "}
          </p>
        </div>
      </div>
    </div>
   
    <div className="sponsorwrapper">
      <h2>Bobcat Partners</h2>
      <div className="sponsorgrid">
        <div> <img width="125px" src={require("../images/Bullpen Logo.png")} /></div>
        <div> <img width="125px" src={require("../images/c5t.png")} /></div>
        <div> <img width="150px" src={require("../images/freedom-center-logo.png")} /></div>
        <div> <img width="125px" src={require("../images/perfect performance.png")} /></div>
        <div> <img width="125px" src={require("../images/SNYDER-logo.png")} /></div>
        <div> <img width="125px" src={require("../images/Mag+Logo.png")} /></div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;

